/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, pageId, url, imageUrl }) {

  useEffect(()=>{
    if(pageId){
      if (window?.fbq) {
        window.fbq('pageView');             
        window.fbq('track', 'page_'+pageId);             
      }
      if (window?.gtag) {
        window.gtag("event", 'page_'+pageId);
      }
    }
  },[pageId]);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `title`,
          content: title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `image`,
          content: imageUrl || 'https://appflynow.com/static/logo-866ef3bb8e091031896ddaf8affbb1f0.png',
        },
        {
          property: `og:image`,
          content: imageUrl || 'https://appflynow.com/static/logo-866ef3bb8e091031896ddaf8affbb1f0.png',
        },
        {
          name: `url`,
          content: url,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: 'y1ppnfktw345elhcxhwcg6341ez0qj',
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pt-BR`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
